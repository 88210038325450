import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';

@Component({
  selector: 'app-modal-image-viewer',
  templateUrl: './modal-image-viewer.component.html',
  styleUrls: ['./modal-image-viewer.component.css'],
  styles: [`
    ::ng-deep .mat-dialog-container{
      padding: 0 !important;
      overflow: hidden;
    }
  `]
})
export class ModalImageViewerComponent {
  public path: string;
  private altPath: string;

  constructor(private dialogRef: MatDialogRef<CommonDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.path = data.path;
    this.altPath = data.altPath;
    this.checkImageAvailability(this.path);
  }

  private checkImageAvailability(imageUrl: string) {
    const img = new Image();
    img.onload = () => {
      // Image loaded successfully, keep the path as is
    };
    img.onerror = () => {
      // Image failed to load, use the alternative path
      this.path = this.altPath;
    };
    img.src = imageUrl;
  }

  close() {
    this.dialogRef.close();
  }

}
